.container {
    padding: 20px 0px;
    height: calc(100vh - 150px);
    background: #ffffff;
    border-radius: 16px;
}
.container-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #1d2939;
}
.user-name {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #6941c6;
}
.view-status {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
    color: #7f56d9;
}

.event-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #344054;
}
.event-description {
    font-weight: 400;
    font-size: 12px;
    color: #344054;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search-field {
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: #667085;
}
.chip-count {
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: #ffffff;
    width: 20px;
    height: 20px;
    background: #7f56d9;
    border-radius: 24px;
}
.chat-item.selected {
    background-color: #f0f0f0;
}

.chat-item {
    height: 100px;
}
.chat-item:hover {
    cursor: pointer;
}
.event-description {
    overflow: 'hidden';
    white-space: 'nowrap';
    text-overflow: 'ellipsis';
}
