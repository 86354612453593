.custom-tooltip .MuiTooltip-tooltip {
    background-color: #7f56d9;
}
.tooltip-container {
    position: relative;
}

.tooltip-container.hovered::after {
    background-size: 28px 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(158, 119, 237, 0.5), rgba(158, 119, 237, 0.5)), url('');
    // opacity: 0.5;
    // background-color: #7f56d9;
    text-align: center;
    z-index: 9999;
    content: url('../../images/drink-vector.svg');
}
.MuiTooltip-tooltip {
    background-color: #7f56d9;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft {
    z-index: 99999;
}
