/* Global styles for the app */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@import './components/';
@import './views/';

:root {
    --theme-font: 'Inter';
    --body-bg: #f2f4f7;
    --primary: #7f56d9;
    --coal: #1a2733;
    --bg-white: #ffffff;
    --modal-hr: #d8d8d8;
    --modal-backdrop: #88c2f5;
    --bg-black: #000000;
    --border-grey: #d3d3d3;
}

body {
    margin: 0;
    font-family: var(--theme-font);
    background-color: var(--body-bg);
}

.w-100 {
    width: 100%;
}

// -----------------global Background Colors-----------------//
img,
button,
a {
    cursor: poInter;
}
.bg-white {
    background-color: var(--bg-white);
}
.bg-primary {
    background-color: var(--primary);
}

p {
    color: var(--coal);
    font-family: Inter;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

// Chips Styles
.cohorts-list {
    border-radius: 8px;
    padding: 20px 4px;
    width: 100%;
    justify-content: left;
    color: var(--coal);
    font-family: var(--theme-font);
    font-size: 14px !important;
    letter-spacing: 0;
    background-color: var(--bg-white);
    line-height: 14px;
    cursor: poInter;
    &:hover {
        background-color: var(--primary);
        color: var(--bg-white);
    }
    &:active {
        background-color: var(--primary);
        color: var(--bg-white);
    }
}

.counter {
    height: 20px !important;
    width: 25px !important;
    border-radius: 4px;
    background-color: #e6e8ea;
    color: var(--coal);
    font-family: var(--theme-font);
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.cohorts-list-box {
    padding: 11px 16px;
    max-height: 340px;
    overflow-y: scroll;
}
.cohorts-list-box::-webkit-scrollbar {
    width: 4px;
}

.cohorts-list-box::-webkit-scrollbar-track {
    border-radius: 2px;
}

.cohorts-list-box::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 2px;
}

.cohorts-list-box::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

// ------------CheckBox Style-------------- //
.css-i4bv87-MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

.MuiFormLabel-root {
    color: var(--coal) !important;
    font-family: 'Inter';
    font-size: 14px;
    letter-spacing: 0;

    line-height: 18px;
}

// -----------------Page Title------------------ //
.pagetile-p {
    width: 400px;
    opacity: 0.5;
    color: #1a2733;
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
}

// ------------------Table Style Starts here------------------//

.MuiTableCell-head {
    color: #1a2733;
    font-family: 'Inter';
    height: 23px;
    font-size: 14px;
    font-weight: bold;
    padding: auto 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.MuiTableCell-body {
    height: auto;
}
.table-row {
    padding: 10px 16px;
    color: var(--coal);
    font-family: 'Inter' !important;
    font-size: 14px !important;
    letter-spacing: 0;
    line-height: 18px !important;
}
// ------------------------Pagination Styles-------------------------//
.MuiTablePagination-select {
    border: 0.5px solid var(--coal);
    border-radius: 2px;
    display: flex;
    width: 40px;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}
.MuiTablePagination-displayedRows {
    color: var(--coal);
    font-family: Inter;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}
.MuiTableSortLabel-icon {
    border-radius: 20px;
    height: 20px;
    width: 20px;
    background-color: #e6e8ea;
    float: right;
    color: #1a2733;
}

//----------------------- Table Sorting Icons Style -----------------------//
.text-red {
    color: red !important;
}
.MuiTableSortLabel-icon {
    margin-left: 1.8rem;
}

//----------------------- Enabled Icons Style -----------------------//

.enabled-icon {
    width: 8px;
    height: 8px;
    border-radius: 25px;
}

// Grid Control
@media (min-width: 1200px) {
    .css-1oqqzyl-MuiContainer-root {
        max-width: 1260px;
    }
}
@media (max-width: 320px) {
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    box-shadow: none !important;
}

.css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root {
    padding: 0px !important;
}

.MuiTypography-root {
    font-family: 'Inter' !important;
}

.MuiBottomNavigationAction-root.Mui-selected {
    color: #7f56d9;
    fill: #7f56d9;
    img {
        filter: invert(36%) sepia(93%) saturate(4258%) hue-rotate(252deg) brightness(87%) contrast(88%);
        fill: #7f56d9 !important;
        // background-color: #7f56d9;
    }
}
