/* Styles for AppBar component */
.AppLogo {
    height: 45px;
    width: auto;
}
.nvabar {
    min-height: auto;
    padding: 4px 0px 0px 0px;
    box-shadow: none;
}
.app-bar {
    height: 100%;
}

.nav-link a {
    text-decoration: none !important;
    color: var(--coal) !important;
    font-family: var(--theme-font);
    padding: 1.7rem 0.4rem;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
    list-style: none;
}

.nav-link a.active {
    border-bottom: 3px solid var(--primary);
    border-radius: 2px 2px 0 0;
}
@media (max-width: 899px) {
    .nav-link {
        padding: 0.8rem 0.9rem !important;
        margin-bottom: 1.9rem;
    }
    .nav-link a {
        width: 100% !important;
    }
}

.mobile-menu-width {
    left: 0px !important;
    top: 3.02rem !important;
    width: 320px !important;
    border-radius: 0px !important;
    padding: 0px auto !important;
}

.main-navbar {
    width: 280px;
    height: 96vh;
    background: white;
    position: fixed;
    // z-index: 9999;
    border-radius: 30px;
    padding: 30px;
    ::-webkit-scrollbar {
        display: none;
    }
}

/* Hide scrollbar for WebKit based browsers */
::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-thumb {
    display: none;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    display: none;
}

.list-item {
    text-decoration: none;
    background: white;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    .item-text {
        color: #344054;
        font-size: 17px;
        line-height: 150%;
    }
    &:hover {
        background: #f9f5ff;
        color: #42307d;
    }
}

.list-item.selected {
    .item-text {
        color: #42307d !important;
    }
    background: #f9f5ff;
    color: #42307d !important;
}

.MuiPaper-root-MuiAppBar-root {
    box-shadow: none !important;
}

.user-dropdown-option {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    &:hover {
        color: #7f56d9;
        & svg {
            color: #7f56d9;
        }
    }
}
