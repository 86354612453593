.fw-bold {
    font-weight: bold !important;
}

.middle-text {
    margin-top: 2%;
}

.cancel-btn {
    padding-left: 4rem;
    padding-right: 4rem;
    border: none;
    cursor: pointer;
}

.confirm-btn {
    border: none;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-left: 1%;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--bg-white);
}

.horizontal-line {
    width: 100%;
    margin: 0;
    margin-top: 5%;
}

.model {
    width: 55%;
    margin-left: 20%;
    height: auto;
    margin-top: 12vh;
    padding: 0px;
    border-radius: 8px;
    background-color: var(--bg-white);
}
.modal-header {
    padding: 1rem;
    border-bottom: 1px solid var(--modal-hr);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-footer {
    padding: 1.2rem 1rem;
}
.modal-body {
    padding: 2rem 1rem;
    border-bottom: 1px solid var(--modal-hr);
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 0.2 !important;
    background: var(--modal-backdrop);
}
@media (max-width: 600px) {
    .model {
        width: 96%;
        margin-left: 2%;
        height: auto;
        margin-top: 6vh;
    }
}

.modal-input {
    width: 100%;
    padding: 0rem 1.5rem 0rem 1.9rem;
}

.modal-items {
    padding: 1rem 0rem;
}
.modal-item {
    padding: 1rem 0rem;
    font-size: 12px;
}

.offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--modal-hr);
}

.offer-modal-footer {
    padding: 1.2rem 1rem;
    height: 30px;
}

.offer-modal-cancel-btn {
    text-transform: none;
    cursor: pointer;
    padding: 0.25rem 3rem;
    margin-right: 0.5rem;
    border: none;
    color: var(--bg-black);
    background-color: var(--modal-hr);
    &:hover {
        background-color: var(--modal-hr);
    }
}

.offer-modal-confirm-btn {
    text-transform: none;
    padding: 0.25rem 3rem;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--bg-white);
    &:hover {
        background-color: var(--primary);
    }
}

.verify-modal-resend-btn {
    margin-left: 1rem;
    cursor: pointer;
    text-transform: none;
    color: var(--bg-black);
    background: var(--bg-white);
    border-radius: 0%;
    border: 1px solid #d3d3d3;
    padding: 0.5rem 2rem;
    &:hover {
        background-color: var(--bg-white);
    }
}
