.btnGroupRight {
    border-right: 0px !important;
}
.rightSide {
    // background: #0099ff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.VerifPin-rightSide {
    // background: #0099ff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.rightSide-forgotPsw {
    // background: #0099ff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.rightSide-setPassword {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width: 440px) {
    .FllWidthBtn {
        width: 100%;
    }
}
