.Table-head-chips {
    border-radius: 0px;
    background-color: #f2f3f4;
    outline: none;
    border: 0.5px solid #bcc0c4;
    font-family: var(--theme-font);
    font-weight: bold;
    font-size: 14px;
}
.MuiChip-deleteIcon {
    background: none;
}
.MuiPaper-root {
    margin-bottom: 0px;
}
