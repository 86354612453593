@keyframes dot-keyframes {
    0% {
        opacity: 0.4;
        transform: scale(1, 1);
    }

    25% {
        opacity: 0.6;
        transform: scale(1.2, 1.2);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.4, 1.4);
    }
    75% {
        opacity: 1;
        transform: scale(1.6, 1.6);
    }

    100% {
        opacity: 0.4;
        transform: scale(1, 1);
    }
}

.loading-dots {
    text-align: center;
    width: 100%;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;

    &--dot {
        animation: dot-keyframes 1.5s infinite ease-in-out;
        background-color: #000;
        border-radius: 10px;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 0.4rem;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
        }
        &:nth-child(4) {
            animation-delay: 0.6s;
        }

        &:nth-child(5) {
            animation-delay: 1s;
        }
    }
}
