.verify {
    text-transform: none;
    padding: 0.25rem 3.5rem;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--bg-white);
    &:hover {
        background-color: var(--primary);
    }
}

.info-text {
    font-size: 12px;
    font-weight: bold;
}

.verify-disable {
    margin-left: 2rem;
    color: white;
    background-color: var(--modal-hr);
    text-transform: none;
    padding: 0.5rem 2.5rem;
    &:hover {
        background-color: var(--modal-hr);
    }
}
