.promotion-img {
    position: absolute;
    top: 25px;
    left: 25%;
    width: 50%;
    object-fit: contain;
}

.promotion-name {
    color: white;
    font-size: 28px;
    line-height: 115%;
    font-weight: bold;
}
.promotion-detail {
    color: white;
    font-size: 17px;
    line-height: 150%;
    font-weight: 400;
    margin-top: 8px;
}

.promotion-img-container {
    min-height: 230px;
    height: 230px;
}

// .reviewing-fields {
//     background-color: #d0d5dd;
//     border-radius: 4px;
//     color: #1d2939;
//     -webkit-text-fill-color: #1d2939;
// }

.reviewing-fields .Mui-disabled {
    background-color: #d0d5dd;
    border-radius: 4px;
    color: #1d2939;
    -webkit-text-fill-color: #1d2939;
}

@media (max-width: 675px) {
    .promotion-name {
        font-size: 20px;
    }
    .promotion-detail {
        font-size: 13px;
    }
}

@media (max-width: 1025px) {
    .promotion-img {
        position: absolute;
        top: 4%;
        left: 30%;
        right: 30%;
        height: 246px;
        width: 246px;
        object-fit: contain;
    }
}

@media (min-width: 1800px) {
    .promotion-img-container {
        min-height: 340px;
        height: 340px;
    }
}

@media (min-width: 2300px) {
    .promotion-img-container {
        min-height: 490px;
        height: 490px;
    }
}
