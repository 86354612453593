.MuiTab-root {
    color: #667085;
    background-color: #d0d5dd;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    border-radius: 10px 10px 0px 0px;
    margin-right: 0.5rem;
}

.MuiTab-root.MuiTabs-indicator {
    height: 0px;
}
.MuiTab-root.Mui-selected.Mui-selected {
    color: #ffffff;
    background-color: #7f56d9;
}
.tab-background {
    background-color: transparent;
}
.panel-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    gap: 20px;
    background: #ffffff;
    border-radius: 16px;
}

.panel-body-payment {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    gap: 20px;
    background: #ffffff;
    border-radius: 16px;
    margin-top: 20px;
}

.css-1oqqzyl-MuiContainer-root {
    padding: 0px;
}
.select-dropdown {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 4px;

    width: 100%;

    /* White */

    background: #ffffff;
    /* Grey/300 */

    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}
.avatar-chip {
    font-weight: 700;
    font-size: '12px';
    width: '20px';
    height: 20px;
    background-color: '#7F56D9';
    border-radius: '100px';
    text-align: 'center';
    padding: '10px';
    overflow: 'inherit';
    color: '#ffffff';
}

.select-dropdown-payment {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px;
    background: #ffffff;
    border-radius: 8px;
    min-width: 300px;
    flex-grow: 0;
}