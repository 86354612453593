.card-container {
    background-color: #ffffff;
    border-radius: 20px;
    padding: '16px';
}
.card-header {
    color: #1d2939;
    font-weight: 600;
    font-size: 17px;
    padding: 2;
}
.image-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    width: 36px;
    height: 36px;
    background: #ecfdf3;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
}
.body-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
}
.body-typo {
    font-weight: 600;
    font-size: 32px;
    color: #344054;
    line-height: 48px;
}
.chip {
    font-weight: 400;
    font-size: '8px';
    color: '#ffffff';
    width: 43px;
    height: 18px;
    background: green;
    padding: 3px;
}
.css-6od3lo-MuiChip-label {
    overflow: inherit !important;
}
