.bg-purple {
    background: #7f56d9;
}
.bg-green {
    background: #12b76a;
}
.bg-grey {
    background: #667085;
}
.offer-date {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px 0px 8px 0px;
    padding: 4px 8px;
    font-size: 11px;
    color: white;
    font-weight: 600;
    font-family: 'Inter';
    font-style: 'normal';
    line-height: 150%;
    gap: 10px;
}
.offer-menu {
    position: absolute;
    left: 5;
    top: 3;
    color: white;
    font-weight: 600;
    font-family: 'Inter';
    background-color: #667085;
}
.offer-title {
    font-size: 17px;
    line-height: 150%;
    color: white;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.offer-subTitle {
    font-size: 13px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.offer-footer {
    font-size: 12px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
