.search-main-div {
    display: flex;
    position: relative;
    height: 40px;
}
.search-main-div input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    width: 100%;

    /* Grey/50 */

    background: #f9fafb;
    /* Grey/300 */

    border: 1px solid #d0d5dd;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.search-icon-main {
    position: absolute;
    top: 0.59rem;
    left: 0.6rem;
}
