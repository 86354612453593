.flex-fill {
    flex: 1 1 auto !important;
}
.user-surveys-side {
    padding: 1.3rem 1.5rem 4rem 0rem;
}
.SmartMatches-side {
    padding: 1.3rem 0rem 4rem 1.5rem;
    border-left: 1px solid #979ea3;
}
@media (max-width: 1200px) {
    .user-surveys-side {
        padding: 2rem 0rem 2rem 0rem;
    }
    .SmartMatches-side {
        padding: 1.3rem 0rem 4rem 0rem;
        border-left: 0px;
    }
}
 .MuiTableSortLabel-root {
     width: 100%;
 }
