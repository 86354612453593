.css-lf6qnn-MuiInputBase-root-MuiOutlinedInput-root {
    height: 17px !important;
    color: var(--coal);
    border-radius: 8px;
    background-color: #d0d5dd !important;
    font-family: var(--theme-font);
}

.MuiCheckbox-root {
    padding: 0px;
}
.input[type='checkbox'] {
    accent-color: var(--primary);
}
textarea {
    width: 100%;
    border: 0.5px solid #d8d8d8;
    padding: 1rem 0rem 0rem 0.5rem;
}
.MuiSelect-select {
    min-height: 23px !important;
}

.MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: default;
    background-color: #f2f4f7 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #d0d5dd !important;
    border-radius: 8px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
